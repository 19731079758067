import React from 'react';
import { SimpleGrid, Box, useBreakpointValue } from '@chakra-ui/react';
import { CardItem } from '../../models';

interface CardProps {
  item: CardItem;
  isSelected: boolean;
  onClick: () => void;
}

interface GridListProps {
  items: CardItem[];
  selectedIds: string[];
  onChangeSelected: (ids: string[]) => void;
  Card: React.ComponentType<CardProps>;
  isMultiSelect?: boolean;
}

export function GridList({ items, selectedIds, onChangeSelected, Card, isMultiSelect = false }: GridListProps) {
  const columns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4, xl: 5 });
  const handleSelect = (id: string) => {
    let newSelectedIds;
    if (isMultiSelect) {
      if (selectedIds.includes(id)) {
        newSelectedIds = selectedIds.filter(selectedId => selectedId !== id);
      } else {
        newSelectedIds = [...selectedIds, id];
      }
    } else {
      newSelectedIds = selectedIds.includes(id) ? [] : [id];
    }
    onChangeSelected(newSelectedIds);
  };

  return (
    <Box overflowY="auto" maxHeight="70%">
      <SimpleGrid columns={columns} spacing={4} px={{ base: 0, lg: 4 }} alignItems="center">
        {items.map(items => (
          <Card
            key={items.id}
            item={items}
            isSelected={selectedIds.includes(items.id)}
            onClick={() => handleSelect(items.id)}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
