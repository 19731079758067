import React from 'react';
import { useCreateSubscription } from '../hooks/pricing/useSubscriptionsSession';
import { PlanCard } from '../../../common/components/Card/PlanCard';
import { FormattedPlanProps } from '../hooks/pricing/usePricingPlansList';

export const PlanCardWrapper: React.FC<{ plan: FormattedPlanProps; isActive: boolean }> = ({ plan, isActive }) => {
  const createNewSubscription = useCreateSubscription(plan.id);

  const isFree = plan.id === 'free-plan';

  return (
    <PlanCard
      {...plan}
      isRecomended={plan.isRecommended}
      isActive={isActive}
      isFree={isFree}
      onClick={isFree ? undefined : createNewSubscription}
    />
  );
};
