import { useUserProfile } from '../../../../api/useUserProfile';
import { usePricingPlansList } from './usePricingPlansList';

export const useActivePlan = () => {
  const { data: userProfile, isLoading: isUserLoading, error: userError } = useUserProfile();
  const { pricingPlansList, isLoading: isPlansLoading, error: plansError } = usePricingPlansList();

  const isLoading = isUserLoading || isPlansLoading;
  const error = userError || plansError;

  if (isLoading || error || !userProfile || !pricingPlansList) {
    return { activePlan: null, isLoading, error };
  }

  const activePlan = pricingPlansList.find(plan => plan.id === userProfile.subscription.plan.id);

  return { activePlan, isLoading, error };
};
