import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '../../common/components/LoadingPage';
import { AppMainLayout } from '../../AppMainLayout';
import { useCreateProject } from '../../features/ProjectCreation/hooks/useCreateProject';
import { ProjectDetails } from './components/ProjectDetails';
import { useProjectDetail } from './hooks/useProjectDeteil';
import { ConfirmationDialog } from '../../common/components/ConfirmationDialog/ConfirmationDialog';
import { useVideoModal } from '../../features/Modals/VideoModal/VideoModal';
import { Icon } from '../../common/components/Icons';

export const ProjectDetailPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { createProject } = useCreateProject();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { open } = useVideoModal();

  const {
    project,
    title,
    isLoading,
    error,
    isDownloading,
    isDeleting,
    handleTitleChange,
    handleTitleBlur,
    handleDownload,
    handleDelete,
  } = useProjectDetail(projectId || '');

  useEffect(() => {
    if (project) {
      const media = project.medias[0];
      if (media && media.status !== 'Completed') {
        navigate(`/create-video?projectId=${projectId}`);
        return;
      }
    }
  }, [project]);

  const handleVideoClick = () => {
    open({ videoPath: project?.medias[0]?.videoFilePath || '' });
  };

  if (isLoading) {
    return <LoadingPage text={t('loading.project')} isFullPage={true} />;
  }
  if (error || !project) {
    return <Box>Error loading project</Box>;
  }
  return (
    <AppMainLayout
      renderAfterMenuItems={() => (
        <Button colorScheme="purple" mt="auto" minH={10} w="100%" onClick={() => createProject('New Project')}>
          {t('button.createProject')}
        </Button>
      )}>
      <Box
        bg="#1A1A1E"
        border="1px solid #3D3D45"
        p={{ base: '4%', sm: '3%', md: '2.5%', lg: '2%' }}
        height={{ base: 'auto', md: '100%' }}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: '1rem', sm: '1.5rem', md: '2rem', xl: '2.5rem' }}
          height={'100%'}>
          <Box position="relative" width={{ base: '100%', md: '50%' }} pb={{ base: '80%', md: '28.5%' }}>
            <Box position="absolute" top="0" left="0" right="0" bottom="0" overflow="hidden" borderRadius="0.5rem">
              <img
                src={project.medias[0]?.previewFilePath || ''}
                alt="Video preview"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                onClick={handleVideoClick}
              />
              <Flex
                className="play-icon"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                bg="rgba(0, 0, 0, 0.7)"
                w="50px"
                h="50px"
                borderRadius="full"
                justifyContent="center"
                alignItems="center"
                opacity={0.8}
                transition="all 0.2s"
                onClick={handleVideoClick}>
                <Icon iconCode="Lucide:Play" color="white" width="24px" height="24px" />
              </Flex>
            </Box>
          </Box>
          <Box width={{ base: '100%', md: '50%' }} mt={{ base: '1.5rem', md: 0 }} height={{ base: 'auto', mb: '90vh' }}>
            <Heading size={{ base: 'md', lg: 'lg' }} color="white" height="8%" mb="2%" textOverflow="ellipsis">
              {t('projectDetail.title')}
            </Heading>
            <Box height="85%" bg="#1A1A1E" borderRadius="md">
              <ProjectDetails
                project={project}
                title={title}
                onTitleChange={e => handleTitleChange(e.target.value)}
                onTitleBlur={handleTitleBlur}
                onDownload={handleDownload}
                onDelete={onOpen}
                isDownloading={isDownloading}
                isDeleting={isDeleting}
              />
            </Box>
          </Box>
        </Flex>

        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleDelete}
          title={t('projectDetail.deleteConfirmation')}
          description={t('projectDetail.deleteWarning')}
          confirmText={t('button.delete')}
          cancelText={t('button.cancel')}
          confirmButtonColor="red"
          cancelButtonColor="purple"
        />
      </Box>
    </AppMainLayout>
  );
};
