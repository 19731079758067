import { useState, useEffect } from 'react';
import { useAuth0, IdToken } from '@auth0/auth0-react';

export const useTokenClaims = () => {
  const { getIdTokenClaims } = useAuth0();
  const [claims, setClaims] = useState<IdToken | null>(null);

  useEffect(() => {
    const fetchClaims = async () => {
      const tokenClaims = await getIdTokenClaims();
      if (tokenClaims) {
        setClaims(tokenClaims);
      }
    };

    fetchClaims();
  }, [getIdTokenClaims]);

  return claims;
};
