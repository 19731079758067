import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PlanResponse, PortalSessionResponse, CreateSubscriptionData } from '../common/models';
import { useFetchWithAuth } from './api.context';

export const useSubscriptionPlans = () => {
  const fetchWithAuth = useFetchWithAuth();

  return useQuery<PlanResponse[], Error>(['subscriptionPlans'], () => fetchWithAuth('/subscriptions'));
};

export const useBillingPortalSession = () => {
  const fetchWithAuth = useFetchWithAuth();

  return useQuery<PortalSessionResponse, Error>(
    ['billingPortalSession'],
    () => fetchWithAuth('/subscriptions/portal-session'),
    { enabled: false },
  );
};

export const useCreateSubscriptionSession = () => {
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetchWithAuth();
  return useMutation(
    (subscriptionData: CreateSubscriptionData) =>
      fetchWithAuth(
        `/subscriptions?planId=${subscriptionData.planId}&successUrl=${encodeURIComponent(subscriptionData.successUrl)}`,
        {
          method: 'POST',
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('subscriptionPlans');
      },
    },
  );
};
