import React from 'react';
import { StepLayout } from '../../../../common/components/Layout/StepLayout';
import { Niche } from '../../../../common/models';
import { GridList } from '../../../../common/components/GridList';
import { useTranslation } from 'react-i18next';
import { IconSelectionCard } from '../../../../common/components/Card/IconSelectionCard';

interface NicheSelectionProps {
  niches: Niche[];
  selectedNicheId: string | null;
  onSelect: (id: string) => void;
  onContinue: () => void;
}

export const NicheSelection: React.FC<NicheSelectionProps> = ({ niches, selectedNicheId, onSelect, onContinue }) => {
  const { t } = useTranslation();

  return (
    <StepLayout title={t('niche.title')} isNextDisabled={selectedNicheId === null} onContinue={onContinue}>
      <GridList
        items={niches}
        selectedIds={selectedNicheId !== null ? [selectedNicheId] : []}
        onChangeSelected={indexes => onSelect(indexes[0])}
        Card={IconSelectionCard}
      />
    </StepLayout>
  );
};
