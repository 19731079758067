import { useMemo } from 'react';
import { useMenuItems } from '../../config/menuConfig';
import { useUserProfile } from '../../api/useUserProfile';
import { useTokenClaims } from '../../auth/useTokenClaims ';

export const useFilteredMenuItems = () => {
  const menuItems = useMenuItems();
  const { data: userProfile, isLoading } = useUserProfile();
  const claims = useTokenClaims();

  const filteredMenuItems = useMemo(() => {
    if (isLoading || !userProfile || !claims) {
      return menuItems;
    }

    return menuItems.filter(item => {
      if (userProfile.subscription.isFree && item.key === 'settings') {
        return false;
      }
      if (!claims.sub.startsWith('auth0|') && item.key === 'changePassword') {
        return false;
      }
      return true;
    });
  }, [menuItems, userProfile, isLoading, claims]);

  return filteredMenuItems;
};
