import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '../../Icons';

interface MobileMenuButtonProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <IconButton
      aria-label="Toggle menu"
      colorScheme="purple"
      icon={isSidebarOpen ? <Icon iconCode="Lucide:X" /> : <Icon iconCode="Lucide:Menu" />}
      onClick={toggleSidebar}
      position={isSidebarOpen ? 'static' : 'fixed'}
      top={4}
      left={4}
      zIndex={'overlay'}
    />
  );
};
