import { useCallback } from 'react';
import { useBillingPortalSession } from '../../api/useSubscriptionPlans';

export const useBillingPortal = () => {
  const { refetch } = useBillingPortalSession();

  const redirectToBillingPortal = useCallback(async () => {
    try {
      const { data } = await refetch();
      if (data?.portalUrl) {
        window.open(data.portalUrl, '_blank', 'noopener,noreferrer');
      } else {
        console.error('Portal URL not available');
      }
    } catch (error) {
      console.error('Error fetching billing portal session:', error);
    }
  }, [refetch]);

  return { redirectToBillingPortal };
};
