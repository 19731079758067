import React from 'react';
import { FinalizeVideo } from '../../components/FinalizeVideo/FinalizeVideo';

interface FinalizeVideoContainerProps {
  onGenerateMore: () => void;
  onGenerate: () => void;
  mediaId: string;
  updateProjectName: (newName: string) => Promise<void>;
}

export const FinalizeVideoContainer: React.FC<FinalizeVideoContainerProps> = ({
  onGenerateMore,
  onGenerate,
  mediaId,
  updateProjectName,
}) => {
  return (
    <FinalizeVideo
      mediaId={mediaId}
      onGenerateMore={onGenerateMore}
      onGenerate={onGenerate}
      updateProjectName={updateProjectName}
    />
  );
};
