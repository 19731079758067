import React from 'react';
import { Drawer, DrawerContent, DrawerOverlay, Box } from '@chakra-ui/react';
import { Sidebar } from '../../Sidebar';
import { MobileMenuButton } from './MobileMenuButton';
import { SidebarProps } from '../../Sidebar/Sidebar';

interface MobileSidebarProps extends SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  sidebarWidth: string;
}

export const MobileSidebar: React.FC<MobileSidebarProps> = ({ isOpen, onToggle, sidebarWidth, ...sidebarProps }) => (
  <Box>
    <MobileMenuButton isSidebarOpen={isOpen} toggleSidebar={onToggle} />
    <Drawer isOpen={isOpen} placement="left" onClose={onToggle} size={'xs'}>
      <DrawerOverlay />
      <DrawerContent>
        <Box
          position="absolute"
          top={4}
          width={sidebarWidth}
          height={'auto'}
          mx={5}
          display="flex"
          justifyContent="flex-end">
          <MobileMenuButton isSidebarOpen={true} toggleSidebar={onToggle} />
        </Box>
        <Sidebar {...sidebarProps} />
      </DrawerContent>
    </Drawer>
  </Box>
);
