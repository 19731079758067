import React from 'react';
import { NicheSelection } from '../../components/NicheSelection/NicheSelection';
import { useTranslation } from 'react-i18next';
import { useNicheList } from '../../../../api/useNicheList';

interface NicheSelectionContainerProps {
  nicheIdSelected?: string | null;
  onContinue: () => void;
  onNicheSelect: (id: string) => void;
}

export const NicheSelectionContainer: React.FC<NicheSelectionContainerProps> = ({
  nicheIdSelected,
  onContinue,
  onNicheSelect,
}) => {
  const { data: niches, isLoading } = useNicheList();
  const { t } = useTranslation();

  if (isLoading) {
    return <div>{t('loading.default')}</div>;
  }

  const handleNicheSelection = (id: string) => {
    onNicheSelect(id);
  };

  return (
    <NicheSelection
      niches={niches || []}
      selectedNicheId={nicheIdSelected || null}
      onSelect={handleNicheSelection}
      onContinue={onContinue}
    />
  );
};
