import { useState, useEffect } from 'react';
import { useProjectList, useUpdateProject } from '../../../api/useProjects';
import { ProjectWithMedia, ProjectUpdate } from '../../../common/models';

export const useProject = (projectId: string | undefined) => {
  const [project, setProject] = useState<ProjectWithMedia | null>(null);
  const { data: projects, isLoading, error } = useProjectList();
  const updateProjectMutation = useUpdateProject();

  useEffect(() => {
    if (projects && projectId) {
      const foundProject = projects.find(p => p.project.id === projectId);
      if (foundProject) {
        setProject(foundProject);
      }
    }
  }, [projects, projectId]);

  const updateProjectName = async (newName: string) => {
    if (project) {
      try {
        const updatedProject: ProjectUpdate = {
          projectId: project.project.id,
          name: newName,
        };
        await updateProjectMutation.mutateAsync(updatedProject);
        setProject({ ...project, project: { ...project.project, name: newName } });
      } catch (error) {
        console.error('Failed to update project name:', error);
      }
    }
  };

  return {
    project,
    isLoading,
    error,
    updateProjectName,
    isUpdating: updateProjectMutation.isLoading,
  };
};
