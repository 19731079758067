import React from 'react';
import { VStack, Image, Heading, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCreateProject } from '../../../features/ProjectCreation/hooks/useCreateProject';

export const EmptyDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { createProject } = useCreateProject();

  return (
    <VStack spacing={6} align="center" justifyContent="center" height="100%">
      <Image src="/images/hologram.png" alt="Hologram" boxSize="200px" />
      <Heading color="white">{t('dashboard.noVideos')}</Heading>
      <Text color="gray.400">{t('dashboard.startSelling')}</Text>
      <Button colorScheme="purple" size="lg" onClick={() => createProject('New Project')}>
        {t('dashboard.createVideo')}
      </Button>
    </VStack>
  );
};
