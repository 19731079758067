import React from 'react';
import { Box, Heading, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';
import { ProjectControlPanel } from './ProjectControlPanel';

interface VideoGridHeaderProps {
  isSelectionMode: boolean;
  toggleSelectionMode: () => void;
  handleSelectAll: () => void;
  onOpen: () => void;
  selectedProjectsCount: number;
  isDeleting: boolean;
  totalProjectsCount: number;
  createProject: (name: string) => void;
}

export const MobileVideoGridHeader: React.FC<VideoGridHeaderProps> = ({
  isSelectionMode,
  toggleSelectionMode,
  handleSelectAll,
  onOpen,
  selectedProjectsCount,
  isDeleting,
  totalProjectsCount,
  createProject,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" mb={6}>
      <Heading color="white" mb={4}>
        {t('dashboard.projects')}
      </Heading>
      <Button
        colorScheme="purple"
        onClick={() => createProject('New Project')}
        leftIcon={<Icon iconCode="Lucide:Download" />}
        mb={4}>
        {t('sidebar.createVideo')}
      </Button>
      <ProjectControlPanel
        isSelectionMode={isSelectionMode}
        toggleSelectionMode={toggleSelectionMode}
        handleSelectAll={handleSelectAll}
        handleDeleteSelected={onOpen}
        selectedProjectsCount={selectedProjectsCount}
        isAllSelected={selectedProjectsCount > 0 && selectedProjectsCount === totalProjectsCount}
        isDeleting={isDeleting}
        isMobile={true}
      />
    </Box>
  );
};
