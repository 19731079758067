import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import DashboardPage from './pages/DashboardPage';
import { VideoCreatePage } from './pages/VideoCreatePage/VideoCreatePage';
import { Auth0Provider } from './auth/Auth0Provider';
import { withAuthGuard } from './auth/AuthGuard';
import { environment } from './config/environment';
import { ApiProvider } from './api/api.context';
import { ProjectDetailPage } from './pages/ProjectDetailPage/ProjectDetailPage';
import { ChangePasswordModalProvider } from './features/ChangePasswordModal/ChangePasswordProvider';
import { VideoProvider } from './features/Modals/VideoModal/VideoModal';
import { PricingProvider } from './features/Modals/PricingModal/PricingModalContent';
import { MobileSidebarProvider } from './common/components/Layout/components/MobileSidebarProvider';

const DashboardPageWithAuthGuard = withAuthGuard(DashboardPage);
const VideoCreatePageWithAuthGuard = withAuthGuard(VideoCreatePage);
const ProjectDetailPageWithAuthGuard = withAuthGuard(ProjectDetailPage);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Auth0Provider>
        <ApiProvider baseUrl={environment.api.baseUrl}>
          <ChakraProvider>
            <ChangePasswordModalProvider>
              <MobileSidebarProvider>
                <VideoProvider>
                  <PricingProvider>
                    <Routes>
                      <Route path="/" element={<DashboardPageWithAuthGuard />} />
                      <Route path="/projects/:projectId" element={<ProjectDetailPageWithAuthGuard />} />
                      <Route path="/create-video" element={<VideoCreatePageWithAuthGuard />} />
                    </Routes>
                  </PricingProvider>
                </VideoProvider>
              </MobileSidebarProvider>
            </ChangePasswordModalProvider>
          </ChakraProvider>
        </ApiProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default App;
