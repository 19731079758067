import { useDeleteProject } from '../../../api/useProjects';
import { useNavigate } from 'react-router-dom';

export const useProjectDeletion = (projectId: string) => {
  const navigate = useNavigate();
  const deleteProject = useDeleteProject();

  const handleDelete = async () => {
    try {
      await deleteProject.mutateAsync(projectId);
      navigate('/');
    } catch (error) {
      console.error('Failed to delete project:', error);
    }
  };

  return { handleDelete, isDeleting: deleteProject.isLoading };
};
