import { steps } from '../../../config/stepsConfig';

export const useInitialStep = <T>() => {
  const determineInitialStep = (data: T): number => {
    for (let i = 0; i < steps.length - 1; i++) {
      const step = steps[i];
      const modelName = step.nameModel === 'hooks' ? 'hookStyle' : step.nameModel;
      if (!modelName) {
        continue;
      }
      const value = data[modelName as keyof T];
      if (!value) {
        return step.index;
      }
    }
    return steps[steps.length - 2].index;
  };

  return {
    determineInitialStep,
  };
};
