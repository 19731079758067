import React from 'react';
import { Text, Circle, Flex, Box, useBreakpointValue } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { Step } from './Steps';

interface StepItemProps {
  step: Step;
  isActive: boolean;
  isCompleted: boolean;
  onClick: () => void;
  isMobile: boolean;
}

export const StepItem: React.FC<StepItemProps> = ({ step, isActive, isCompleted, isMobile, onClick }) => {
  const { t } = useTranslation();
  const circleSize = useBreakpointValue({ base: '30px', md: '40px', lg: '50px' });
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' });

  return (
    <Flex align="center" flexDirection={isMobile ? 'column' : 'row'} onClick={onClick} cursor="pointer">
      <Circle size={circleSize} bg={isActive ? 'purple.500' : 'gray.700'} color="white" fontWeight="bold">
        {isCompleted ? <Icon iconCode={'Lucide:Check'} /> : step.index}
      </Circle>
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        ml={3}>
        <Text color="white" fontWeight="bold" fontSize={fontSize}>
          {t('steps.step', { number: step.index })}
        </Text>
        <Text color={isActive ? 'purple.500' : 'gray.400'} fontSize={fontSize}>
          {step.label}
        </Text>
      </Box>
    </Flex>
  );
};
