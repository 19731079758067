import { useTranslation } from 'react-i18next';

export const useDateFormatter = () => {
  const { i18n } = useTranslation();

  const formatDate = (date: Date | string) => {
    const dateObject = date instanceof Date ? date : new Date(date);
    return new Intl.DateTimeFormat(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(dateObject);
  };

  return formatDate;
};
