import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface ChangePasswordModalContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const ChangePasswordModalContext = createContext<ChangePasswordModalContextType | undefined>(undefined);

export const ChangePasswordModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const value: ChangePasswordModalContextType = useMemo(
    () => ({
      isOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [isOpen],
  );

  return <ChangePasswordModalContext.Provider value={value}>{children}</ChangePasswordModalContext.Provider>;
};

export const useChangePasswordModal = () => {
  const context = useContext(ChangePasswordModalContext);
  if (context === undefined) {
    throw new Error('useChangePasswordModal must be used within a ChangePasswordModalProvider');
  }
  return context;
};
