import React from 'react';
import { SimpleGrid, Box, Checkbox, useDisclosure } from '@chakra-ui/react';
import { VideoCard } from '../../../common/components/Card/VideoCard';
import { ProjectWithMedia } from '../../../common/models';
import { useTranslation } from 'react-i18next';
import { useCreateProject } from '../../../features/ProjectCreation/hooks/useCreateProject';
import { useProjectSelection } from '../hooks/useProjectSelection';
import { useProjectDeletion } from '../hooks/useProjectDeletion';
import { LoadingPage } from '../../../common/components/LoadingPage';
import { ConfirmationDialog } from '../../../common/components/ConfirmationDialog/ConfirmationDialog';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { MobileVideoGridHeader } from '../components/MobileVideoGridHeader';
import { DesktopVideoGridHeader } from '../components/DesktopVideoGridHeader';

interface VideoGridProps {
  projects: ProjectWithMedia[];
}

export const VideoGridDashboard: React.FC<VideoGridProps> = ({ projects }) => {
  const { t } = useTranslation();
  const { createProject } = useCreateProject();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useIsMobile();

  const {
    selectedProjects,
    isSelectionMode,
    handleSelectProject,
    handleCardClick,
    handleSelectAll,
    toggleSelectionMode,
  } = useProjectSelection(projects);
  const { handleDeleteSelected, isDeleting } = useProjectDeletion();

  const onDeleteSelected = () => {
    handleDeleteSelected(selectedProjects);
    onClose();
  };

  if (isDeleting) {
    return <LoadingPage text={t('loading.deleting')} />;
  }

  return (
    <Box height="100%">
      {isMobile ? (
        <MobileVideoGridHeader
          isSelectionMode={isSelectionMode}
          toggleSelectionMode={toggleSelectionMode}
          handleSelectAll={handleSelectAll}
          onOpen={onOpen}
          selectedProjectsCount={selectedProjects.length}
          isDeleting={isDeleting}
          createProject={createProject}
          totalProjectsCount={projects.length}
        />
      ) : (
        <DesktopVideoGridHeader
          isSelectionMode={isSelectionMode}
          toggleSelectionMode={toggleSelectionMode}
          handleSelectAll={handleSelectAll}
          onOpen={onOpen}
          selectedProjectsCount={selectedProjects.length}
          isDeleting={isDeleting}
          createProject={createProject}
          totalProjectsCount={projects.length}
        />
      )}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 6 }} spacing={4} width="100%">
        {projects.map((video: ProjectWithMedia) => (
          <Box
            key={video.project.id}
            position="relative"
            onClick={() => handleCardClick(video)}
            cursor={'pointer'}
            _hover={isSelectionMode ? { opacity: 0.8 } : {}}>
            {isSelectionMode && (
              <Checkbox
                position="absolute"
                top={2}
                left={2}
                zIndex={1}
                isChecked={selectedProjects.includes(video.project.id)}
                onChange={() => handleSelectProject(video.project.id)}
                colorScheme="purple"
              />
            )}
            <VideoCard
              key={video.project.id}
              title={video.project.name}
              thumbnailUrl={video.medias[0]?.previewFilePath || ''}
              createdAt={video.project.createdAt}
            />
          </Box>
        ))}
      </SimpleGrid>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onDeleteSelected}
        title={t('projectDetail.deleteConfirmation')}
        description={t('projectDetail.deleteWarning')}
        confirmText={t('button.delete')}
        cancelText={t('button.cancel')}
        confirmButtonColor="red"
        cancelButtonColor="purple"
      />
    </Box>
  );
};
