import { useCreateSubscriptionSession } from '../../../../api/useSubscriptionPlans';
import { CreateSubscriptionData } from '../../../../common/models';

export const useCreateSubscription = (planId: string) => {
  const createSubscriptionSession = useCreateSubscriptionSession();

  const createNewSubscription = () => {
    const subscriptionData: CreateSubscriptionData = {
      planId,
      successUrl: window.location.origin,
    };

    createSubscriptionSession.mutate(subscriptionData, {
      onSuccess: data => {
        if (data?.paymentUrl) {
          window.location.href = data.paymentUrl;
        } else {
          console.error('Failed to get payment URL');
        }
      },
      onError: error => {
        console.error('Error creating subscription:', error);
      },
    });
  };

  return createNewSubscription;
};
