import React from 'react';
import { VStack, Text, Badge, Button, HStack, Box } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { Card, CardProps } from './Card';

export interface PlanProps {
  name: string;
  price: string;
  features: string[];
  onClick?: () => void;
}

interface PlanCardProps extends PlanProps {
  isRecomended?: boolean;
  isActive?: boolean;
  isFree?: boolean;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  name,
  price,
  features,
  isRecomended = false,
  isActive = false,
  isFree = false,
  onClick,
}) => {
  const { t } = useTranslation();
  const cardVariant: CardProps['variant'] = isRecomended ? 'pricing-second' : 'pricing';

  return (
    <Card
      variant={cardVariant}
      width={{ base: '100%', sm: '280px' }}
      minHeight={{ base: 'auto', sm: '100%' }}
      position="relative"
      border={isActive ? '3px solid #48BB78' : '3px solid #3D3D45'}>
      <VStack align="stretch" height="100%" spacing={0}>
        <VStack
          p={6}
          width="100%"
          borderBottom={isRecomended ? '2px solid #5F19BA' : '1px solid #3D3D45'}
          justifyContent="space-between"
          alignItems="flex-start"
          minHeight={{ base: 'auto', sm: '100px' }}
          spacing={3}>
          <Text fontWeight="bold" fontSize="2xl" color="white">
            {name}
          </Text>
          {isRecomended && (
            <Badge
              bg="linear-gradient(180deg, rgba(207, 170, 255, 0.15) 6.25%, rgba(152, 105, 213, 0.15) 100%)"
              color="#9747FF"
              border="1px solid"
              borderColor="linear-gradient(170.54deg, #9747FF 10.56%, rgba(151, 71, 255, 0.4) 92.86%)"
              px={3}
              py={1}
              borderRadius="full"
              fontSize="sm">
              <Icon iconCode="Lucide:Zap" mr={1} boxSize={3} />
              {t('pricing.recommended')}
            </Badge>
          )}
          {isActive && (
            <Badge colorScheme="green" variant="solid" borderRadius="full" px={3} py={1} fontSize="sm">
              {t('pricing.currentPlan')}
            </Badge>
          )}
        </VStack>
        <VStack p={6} width="100%" align="stretch" spacing={5} flex={1}>
          <VStack align="stretch" spacing={5} flex={1}>
            <Box fontSize="4xl" fontWeight="bold" color="white" lineHeight="1.2">
              <Text as="span">{price.split('/')[0]}</Text>
              <Text as="span" fontSize="lg" ml={1}>
                /{price.split('/')[1]}
              </Text>
            </Box>
            <VStack spacing={4} align="stretch">
              {features.map((feature, index) => (
                <HStack key={index} spacing={3} align="flex-start">
                  <Text color="green.500" fontSize="xl" lineHeight="1.4">
                    ✓
                  </Text>
                  <Text color="gray.300" fontSize="md" lineHeight="1.4">
                    {feature}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
          {!isActive && !isFree && (
            <Box mt="auto">
              <Button
                bg="purple.500"
                width="100%"
                color="white"
                size="lg"
                p={2}
                borderRadius="md"
                _hover={{ bg: 'purple.600' }}
                onClick={onClick}>
                {t('button.buy')}
              </Button>
            </Box>
          )}
        </VStack>
      </VStack>
    </Card>
  );
};
