import React from 'react';
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StepItem } from './StepItem';

export interface Step {
  index: number;
  labelKey: string;
  label?: string;
  nameModel?: string;
  isCompleted?: boolean;
}

interface StepsProps {
  steps: Step[];
  isMobile: boolean;
  currentStep: number;
  onChange: (step: number) => void;
}

export const Steps: React.FC<StepsProps> = ({ steps, currentStep, isMobile, onChange }) => {
  const { t } = useTranslation();
  const spacing = useBreakpointValue({ base: 2, md: 6 });
  const boxSize = useBreakpointValue({ base: '50px', md: '70px', lg: '90px' });
  const connectorWidth = useBreakpointValue({ base: '10px', md: '50px', lg: '70px' });
  const stepsWithDefaults = steps.map(step => ({
    ...step,
    isCompleted: step.isCompleted ?? false,
  }));

  return (
    <Flex
      justifyContent="center"
      mb={spacing}
      alignItems="center"
      width="100%"
      py={{ base: 2, mb: 0 }}
      wrap="wrap"
      maxHeight="30%">
      {stepsWithDefaults.map((step, index) => (
        <Flex key={step.index} flexDirection="row" align="center">
          <Box
            key={step.index}
            minWidth={boxSize}
            minHeight={boxSize}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <StepItem
              isMobile={isMobile}
              step={{ ...step, label: t(step.labelKey) }}
              isActive={currentStep === step.index}
              isCompleted={currentStep !== step.index && step.isCompleted}
              onClick={() => onChange(step.index)}
            />
          </Box>
          {index < steps.length - 1 && <Box flex={1} height={'2px'} bg="gray.700" mx={2} width={connectorWidth} />}
        </Flex>
      ))}
    </Flex>
  );
};
