import React from 'react';
import { Box, Skeleton, SkeletonText, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { usePricingModal } from '../Modals/PricingModal/PricingModalContent';
import { useUserProfile } from '../../api/useUserProfile';
import { FreePlanBadge } from './components/FreePlanBadge';
import { CreditsProgress } from './components/CreditsProgress';

export const UserInfoBox: React.FC = () => {
  const { t } = useTranslation();
  const { open } = usePricingModal();
  const { data: userInfo, isLoading } = useUserProfile();
  if (isLoading) {
    return (
      <Box border="1px solid #3D3D45" borderRadius="md" py={4} mb={5}>
        <Box px={4} mb={5} borderBottom="1px solid #3D3D45">
          <Skeleton height="20px" width="200px" mb={2} />
        </Box>
        <Box px={4}>
          <SkeletonText mt={2} noOfLines={1} spacing={4} width="120px" />
          <Skeleton height="60px" mt={4} />
        </Box>
      </Box>
    );
  }
  if (!userInfo) {
    console.log('Error loading user info');
    return <Text>{t('loading.userInfo')}</Text>;
  }

  return (
    <Box border="1px solid #3D3D45" borderRadius="md" py={4} mb={5}>
      <Box px={4} mb={5} borderBottom="1px solid #3D3D45">
        <Text fontSize="sm" mb={2}>
          {userInfo.user.email}
        </Text>
      </Box>
      <Box px={4}>
        {userInfo.subscription.isFree && <FreePlanBadge />}
        {!userInfo.subscription.plan.isVideoGenerationsUnlimited && (
          <CreditsProgress
            currentCredits={userInfo.subscription.currentVideoGenerations}
            maxCredits={userInfo.subscription.plan.videoGenerationsPerInterval}
            interval={userInfo.subscription.plan.interval}
            onAddCredits={() => open({})}
          />
        )}
      </Box>
    </Box>
  );
};
