import { useUpdateMedia } from '../../../api/useMedia';
import { UpdateMediaData } from '../../../common/models';
import { SelectedData } from './useVideoCreation';

export const useUpdateMediaData = (selectedData: SelectedData, mediaID: string) => {
  const updateMedia = useUpdateMedia();

  const updateMediaData = async () => {
    const mediaChangeData: UpdateMediaData = {
      nicheId: selectedData.niche || null,
      avatarId: selectedData.avatar || null,
      productId: selectedData.product || null,
      hookStyleId: selectedData.hooks || null,
      cta: selectedData.ctaLink || null,
      mediaId: mediaID,
    };

    try {
      let response;
      if (mediaID) {
        response = await updateMedia.mutateAsync({ mediaId: mediaID, mediaData: mediaChangeData });
      }
      return response;
    } catch (error) {
      console.error('Failed to update or create media:', error);
    }
  };

  return updateMediaData;
};
