import React, { createContext, useContext, ReactNode } from 'react';
import { useDisclosure } from '@chakra-ui/react';

interface MobileSidebarContextType {
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
}

const MobileSidebarContext = createContext<MobileSidebarContextType | undefined>(undefined);

export const MobileSidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <MobileSidebarContext.Provider value={{ isOpen, onToggle, onClose }}>{children}</MobileSidebarContext.Provider>
  );
};

export const useMobileSidebar = () => {
  const context = useContext(MobileSidebarContext);
  if (context === undefined) {
    throw new Error('useMobileSidebar must be used within a MobileSidebarProvider');
  }
  return context;
};
