import { useMutation, useQueryClient } from 'react-query';
import { useFetchWithAuth } from './api.context';
import { UpdateMediaData } from '../common/models';

export const useUpdateMedia = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  return useMutation(
    ({ mediaId, mediaData }: { mediaId: string; mediaData: UpdateMediaData }) =>
      fetchWithAuth(`/media/${mediaId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mediaData),
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['media', variables.mediaId]);
      },
    },
  );
};

export const useGenerateMedia = () => {
  const fetchWithAuth = useFetchWithAuth();

  return useMutation((mediaId: string) => fetchWithAuth(`/media/${mediaId}/generate`, { method: 'POST' }));
};
