import {
  MutationOptions,
  QueryClient,
  QueryClientProvider as RQQueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export interface FetchAuthContext {
  baseUrl: string;
}

const FetchWithAuthContext = createContext({} as FetchAuthContext);

const queryClient = new QueryClient();

export interface ApiProviderProps extends PropsWithChildren {
  baseUrl: string;
}

export const ApiProvider = ({ baseUrl, children }: ApiProviderProps) => {
  return (
    <RQQueryClientProvider client={queryClient}>
      <FetchWithAuthContext.Provider value={{ baseUrl }}>{children}</FetchWithAuthContext.Provider>
    </RQQueryClientProvider>
  );
};

// TODO: Remove any
// eslint-disable-next-line
const fetchWithAuth = async <T = any,>(url: string, token: string, options: RequestInit = {}): Promise<T> => {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    try {
      const data = await response.json();
      return data;
    } catch {
      throw new Error('Failed to parse JSON');
    }
  } else if (response.status === 204 || response.status === 205) {
    return null as T;
  }

  return null as T;
};

/**
 * @deprecated
 */
export const useFetchWithAuth = () => {
  const { baseUrl } = useContext(FetchWithAuthContext);
  const { getAccessTokenSilently } = useAuth0();

  // TODO: Remove any
  // eslint-disable-next-line
  return async <T = any,>(endpoint: string, options: RequestInit = {}): Promise<T> =>
    fetchWithAuth<T>(`${baseUrl}${endpoint}`, await getAccessTokenSilently(), options);
};

export const createUseQueryWithAuth =
  <P extends unknown[], R>(
    key: string,
    getEndpoint: (...params: P) => string,
    getOptions?: (...params: P) => RequestInit,
  ) =>
  (...params: P) => {
    const { baseUrl } = useContext(FetchWithAuthContext);
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
      queryKey: [baseUrl, key, ...params],
      // eslint-disable-next-line
      queryFn: async ({ queryKey: [baseUrl, _, ...params] }) => {
        const token = await getAccessTokenSilently();
        const paramsTyped = params as unknown as P;
        return await fetchWithAuth<R>(`${baseUrl}${getEndpoint(...paramsTyped)}`, token, getOptions?.(...paramsTyped));
      },
    });
  };

export const createUseMutationWithAuth =
  <T, R>(
    key: string,
    getEndpoint: (variables: T) => string,
    getOptions: (variables: T) => RequestInit,
    getMutationOptions?: (queryClient: QueryClient) => Omit<MutationOptions<R, Error, T>, 'mutationKey' | 'mutationFn'>,
  ) =>
  () => {
    const { baseUrl } = useContext(FetchWithAuthContext);
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation({
      mutationKey: [baseUrl, key],
      mutationFn: async (variables: T) => {
        const token = await getAccessTokenSilently();
        return await fetchWithAuth<R>(`${baseUrl}${getEndpoint(variables)}`, token, getOptions(variables));
      },
      ...(getMutationOptions?.(queryClient) || {}),
    });
  };
