import { useState } from 'react';
import { SelectedData } from './useVideoCreation';
import { useStepValidation } from './useStepValidation';

export const useSteps = (totalSteps: number, initialStep: number = 1) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const { canNavigateToStep } = useStepValidation();

  const nextStep = () => {
    handleStepChange(currentStep + 1);
  };

  const changeStep = (step: number, selectedData: SelectedData) => {
    if (step === totalSteps || currentStep === totalSteps) {
      return;
    }
    if (currentStep < step) {
      if (!canNavigateToStep(step, selectedData)) {
        console.warn('Cannot navigate to this step');
        return;
      }
    }
    handleStepChange(step);
  };

  const restartStep = () => {
    handleStepChange(1);
  };

  const handleStepChange = (step: number) => {
    if (step < 1 || step > totalSteps) {
      console.error(`Invalid step: ${step}. Step must be between 1 and ${totalSteps}.`);
      return;
    }
    setCurrentStep(step);
  };

  return {
    currentStep,
    changeStep,
    restartStep,
    nextStep,
  };
};
