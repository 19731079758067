import { useQuery, useMutation, useQueryClient } from 'react-query';
import { CreateProjectData, ProjectWithMedia } from '../common/models';
import { useFetchWithAuth } from './api.context';

export const useProjectList = () => {
  const fetchWithAuth = useFetchWithAuth();
  const fetchProjects = () => fetchWithAuth('/projects/my');
  return useQuery<ProjectWithMedia[], Error>('projects', fetchProjects);
};

export const useCreateProject = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (projectData: CreateProjectData) =>
      fetchWithAuth(`/projects`, { method: 'POST', body: JSON.stringify(projectData) }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('projects');
      },
    },
  );
};

export const useUpdateProject = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (projectData: { projectId: string; name: string }) =>
      fetchWithAuth('/projects', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(projectData),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('projects');
      },
    },
  );
};

export const useDeleteProject = () => {
  const fetchWithAuth = useFetchWithAuth();
  const queryClient = useQueryClient();

  return useMutation((projectId: string) => fetchWithAuth(`/projects/${projectId}`, { method: 'DELETE' }), {
    onSuccess: () => {
      queryClient.invalidateQueries('projects');
    },
  });
};
