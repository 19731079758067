import React, { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';
import { Background } from '../Background';
import { MenuItem } from '../Sidebar';
import { MainContent } from './components/MainContent';
import { useIsMobile } from '../../hooks/useIsMobile';
import { DesktopSidebar } from './components/DesktopSidebar';
import { MobileSidebar } from './components/MobileSidebar';
import { useChangePasswordModal } from '../../../features/ChangePasswordModal/ChangePasswordProvider';
import { ChangePasswordModal } from '../../../features/ChangePasswordModal/ChangePasswordModal';
import { useMobileSidebar } from './components/MobileSidebarProvider';

export interface MainLayoutProps {
  children: React.ReactNode;
  header?: string;
  menuItems: MenuItem[];
  renderBeforeMenuItems?: () => ReactElement;
  renderAfterMenuItems?: () => ReactElement;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  header,
  menuItems,
  renderBeforeMenuItems,
  renderAfterMenuItems,
}) => {
  const { isOpen: isSidebarOpen, onToggle: toggleSidebar } = useMobileSidebar();
  const { isOpen: isChangePasswordOpen, close: closeChangePassword } = useChangePasswordModal();
  const isMobile = useIsMobile();
  const sidebarWidth = '280px';

  return (
    <Background>
      <Flex h="100vh" overflow="hidden">
        {isMobile ? (
          <MobileSidebar
            isOpen={isSidebarOpen}
            onToggle={toggleSidebar}
            header={header}
            sidebarWidth={sidebarWidth}
            menuItems={menuItems}
            renderBeforeMenuItems={renderBeforeMenuItems}
            renderAfterMenuItems={renderAfterMenuItems}
          />
        ) : (
          <DesktopSidebar
            sidebarWidth={sidebarWidth}
            header={header}
            menuItems={menuItems}
            renderBeforeMenuItems={renderBeforeMenuItems}
            renderAfterMenuItems={renderAfterMenuItems}
          />
        )}
        <MainContent>{children}</MainContent>
      </Flex>
      <ChangePasswordModal isOpen={isChangePasswordOpen} onClose={closeChangePassword} />
    </Background>
  );
};
