import { useState } from 'react';
import { environment } from '../config/environment';

interface ChangePasswordParams {
  email: string;
}

export const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const changePassword = async ({ email }: ChangePasswordParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://${environment.auth.domain}/dbconnections/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: environment.auth.clientId,
          email,
          connection: 'Username-Password-Authentication',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to initiate password change');
      }

      const data = await response.text();
      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { changePassword, isLoading, error };
};
