import React from 'react';
import { Box } from '@chakra-ui/react';

interface MainContentProps {
  children: React.ReactNode;
}

export const MainContent: React.FC<MainContentProps> = ({ children }) => (
  <Box flex="1" overflowY="auto" h="100vh">
    <Box height="100%" p={{ base: 4, md: 8 }}>
      {children}
    </Box>
  </Box>
);
