import { useCreateProject as useCreateProjectMutation } from '../../../api/useProjects';
import { useNavigate } from 'react-router-dom';
import { CreateProjectData } from '../../../common/models';

export const useCreateProject = () => {
  const navigate = useNavigate();
  const createProjectMutation = useCreateProjectMutation();

  const createProject = async (name: string) => {
    try {
      const initialProjectData: CreateProjectData = {
        nicheId: null,
        avatarId: null,
        hookStyleId: null,
        cta: null,
        name: name,
      };
      const response = await createProjectMutation.mutateAsync(initialProjectData);
      navigate(`/create-video?projectId=${response.project.id}`);
    } catch (error) {
      console.error('Failed to create project:', error);
    }
  };

  return { createProject, isLoading: createProjectMutation.isLoading };
};
