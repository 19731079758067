import { steps } from '../../../config/stepsConfig';

export const useStepValidation = <T>() => {
  const canNavigateToStep = (targetStep: number, data: T): boolean => {
    const previousStep = steps.find(s => s.index === targetStep - 1);
    if (!previousStep?.nameModel) return true;

    return !!data[previousStep.nameModel as keyof T];
  };

  return {
    canNavigateToStep,
  };
};
