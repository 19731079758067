import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { usePricingModal } from '../features/Modals/PricingModal/PricingModalContent';

import { MenuItem } from '../common/components/Sidebar';
import { useTranslation } from 'react-i18next';
import { useBillingPortal } from '../features/BillingPortal/useBillingPortal';
import { useChangePasswordModal } from '../features/ChangePasswordModal/ChangePasswordProvider';

export const useMenuItems = (): MenuItem[] => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { open: openPricingModal } = usePricingModal();
  const { open: openChangePasswordModal } = useChangePasswordModal();
  const { t } = useTranslation();
  const { redirectToBillingPortal } = useBillingPortal();

  return [
    {
      key: 'home',
      name: t('sidebar.home'),
      icon: 'Lucide:House',
      onClick: () => navigate('/'),
    },
    {
      key: 'billing',
      name: t('sidebar.billing'),
      icon: 'Lucide:IdCard',
      onClick: () => openPricingModal({}),
    },
    {
      key: 'settings',
      name: t('sidebar.settings'),
      icon: 'Lucide:Package',
      onClick: redirectToBillingPortal,
    },
    {
      key: 'changePassword',
      name: t('sidebar.changePassword'),
      icon: 'Lucide:Lock',
      onClick: openChangePasswordModal,
    },
    {
      key: 'logout',
      name: t('sidebar.logout'),
      icon: 'Lucide:LogOut',
      onClick: () =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        }),
    },
  ];
};
