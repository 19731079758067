import React from 'react';
import { Auth0Provider as Auth0ProviderBase } from '@auth0/auth0-react';
import { environment } from '../config/environment';

export const Auth0Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Auth0ProviderBase
      domain={environment.auth.domain}
      clientId={environment.auth.clientId}
      authorizationParams={{
        audience: environment.auth.audience,
        redirect_uri: environment.auth.redirectUri,
      }}>
      {children}
    </Auth0ProviderBase>
  );
};
