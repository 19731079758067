import React, { useMemo, useState, useContext, ReactNode, PropsWithChildren } from 'react';
import { UniversalModal } from './UniversalModal';
import { ModalContentProps } from '@chakra-ui/react';

export interface ModalContextValue<T> {
  open: (props: T) => void;
  close: () => void;
  isOpen: boolean;
}
export interface modalOptions {
  size?: string;
  showCloseButton?: boolean;
  contentProps?: ModalContentProps;
}

export type ModalComponents<T, K extends string> = {
  [P in `${K}Provider`]: React.FC<PropsWithChildren>;
} & {
  [P in `use${K}Modal`]: () => ModalContextValue<T>;
};

export const createModal = <T extends object, K extends string>(
  Component: React.ComponentType<T>,
  name: K,
  modalOptions?: modalOptions,
): ModalComponents<T, K> => {
  const ProviderName = name + 'Provider';
  const useModalName = 'use' + name + 'Modal';
  const ContextName = name + 'Context';

  const Context = React.createContext<ModalContextValue<T> | undefined>(undefined);
  Context.displayName = ContextName;

  const Provider = ({ children }: { children: ReactNode }) => {
    const [modalProps, setModalProps] = useState<T | null>(null);
    const isOpen = !!modalProps;

    const value = useMemo(
      (): ModalContextValue<T> => ({
        open: (props: T) => setModalProps({ ...props } as T),
        close: () => setModalProps(null),
        isOpen,
      }),
      [isOpen],
    );

    return (
      <Context.Provider value={value}>
        <UniversalModal
          renderContent={() => (modalProps ? <Component {...modalProps} /> : null)}
          isOpen={isOpen}
          close={() => setModalProps(null)}
          size={modalOptions?.size}
          showCloseButton={modalOptions?.showCloseButton}
          contentProps={modalOptions?.contentProps}
        />
        {children}
      </Context.Provider>
    );
  };
  Provider.displayName = ProviderName;

  const useModal = () => {
    const context = useContext(Context);
    if (!context) {
      throw new Error(`use${name} must be used within a ${name}Provider`);
    }
    return context;
  };
  useModal.displayName = useModalName;

  return { [ProviderName]: Provider, [useModalName]: useModal } as ModalComponents<T, K>;
};
