import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalContentProps,
} from '@chakra-ui/react';

interface UniversalModalProps {
  renderContent: () => React.ReactNode;
  title?: string;
  isOpen: boolean;
  close: () => void;
  size?: string;
  footer?: React.ReactNode;
  showCloseButton?: boolean;
  contentProps?: ModalContentProps;
}

export const UniversalModal: React.FC<UniversalModalProps> = ({
  renderContent,
  title = '',
  isOpen,
  close,
  size = 'md',
  footer = null,
  showCloseButton = true,
  contentProps = {},
  ...rest
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={close} size={size} {...rest}>
      <ModalOverlay />
      <ModalContent bg="#1A1A1A" color="white" {...contentProps}>
        {title && <ModalHeader>{title}</ModalHeader>}
        {showCloseButton && <ModalCloseButton zIndex="modal" />}
        <ModalBody>{renderContent()}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};
