import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface BackgroundProps extends BoxProps {
  children: React.ReactNode;
  minHeight?: string;
}

export const Background: React.FC<BackgroundProps> = ({ children, minHeight = '100vh', ...rest }) => (
  <Box
    backgroundImage="url('/images/background.png')"
    backgroundSize="cover"
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    minHeight={minHeight}
    width="100%"
    {...rest}>
    {children}
  </Box>
);

export default Background;
