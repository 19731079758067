import React from 'react';
import { Box } from '@chakra-ui/react';
import { Sidebar } from '../../Sidebar';
import { SidebarProps } from '../../Sidebar/Sidebar';

interface DesktopSidebarProps extends SidebarProps {
  sidebarWidth: string;
}

export const DesktopSidebar: React.FC<DesktopSidebarProps> = ({ sidebarWidth, ...sidebarProps }) => (
  <Box width={sidebarWidth}>
    <Sidebar {...sidebarProps} />
  </Box>
);
