import React from 'react';
import { VStack, Box, Text, Input, HStack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProjectWithMedia } from '../../../common/models';
import { ProjectDetailItem } from './ProjectDetailItem';
import { useProjectDetailItems } from '../../../features/ProjectDetails/useProjectDetailItems';
import { Icon } from '../../../common/components/Icons';
import { useDateFormatter } from '../../../common/hooks/useDateFormatter';

interface ProjectDetailsProps {
  project: ProjectWithMedia;
  title: string;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTitleBlur: () => void;
  onDownload: () => void;
  onDelete: () => void;
  isDownloading: boolean;
  isDeleting: boolean;
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  title,
  onTitleChange,
  onTitleBlur,
  onDownload,
  onDelete,
  isDownloading,
  isDeleting,
}) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();
  const detailItems = useProjectDetailItems(project.medias[0] || null);

  return (
    <VStack align="stretch" spacing={{ base: '1.5rem', md: '2rem' }} height="100%">
      <Box>
        <Text color="white" mb="0.5rem" fontSize={{ base: '0.9rem', md: '1rem' }}>
          {t('projectDetail.videoTitle')}
        </Text>
        <Input
          value={title}
          onChange={onTitleChange}
          onBlur={onTitleBlur}
          bg="#27272A"
          border="1px solid #3D3D45"
          color="white"
          fontSize={{ base: '0.9rem', md: '1rem' }}
          height={{ base: '2.5rem', md: '3rem' }}
        />
      </Box>

      <Box
        flex="1"
        overflow={{ base: 'visible', md: 'auto' }}
        css={{
          '@media screen and (min-width: 48em)': {
            // 768px - md breakpoint
            '&::-webkit-scrollbar': { width: '4px' },
            '&::-webkit-scrollbar-track': { background: '#27272A' },
            '&::-webkit-scrollbar-thumb': {
              background: '#3D3D45',
              borderRadius: '2px',
            },
          },
        }}>
        <VStack spacing={{ base: '1rem', md: '1.5rem' }} align="stretch" height={{ base: 'auto', md: '100%' }}>
          <ProjectDetailItem label={t('projectDetail.createdAt')} value={formatDate(project.project.createdAt)} />
          {detailItems.map(
            (item, index) => item.value && <ProjectDetailItem key={index} label={item.label} value={item.value} />,
          )}
        </VStack>
      </Box>

      <HStack spacing="1rem">
        <Button
          leftIcon={<Icon iconCode={'Lucide:Download'} />}
          colorScheme="purple"
          flex="1"
          onClick={onDownload}
          isLoading={isDownloading}
          loadingText={t('button.downloading')}
          fontSize={{ base: '0.9rem', md: '1rem' }}
          height={{ base: '2.5rem', md: '3rem' }}>
          {t('button.download')}
        </Button>
        <Button
          leftIcon={<Icon iconCode={'Lucide:Trash'} />}
          colorScheme="red"
          flex="1"
          onClick={onDelete}
          isLoading={isDeleting}
          fontSize={{ base: '0.9rem', md: '1rem' }}
          height={{ base: '2.5rem', md: '3rem' }}>
          {t('button.delete')}
        </Button>
      </HStack>
    </VStack>
  );
};
