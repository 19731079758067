import React from 'react';
import { StepLayout } from '../../../../common/components/Layout/StepLayout';
import { Product } from '../../../../common/models';
import { GridList } from '../../../../common/components/GridList';
import { useTranslation } from 'react-i18next';
import { IconSelectionCard } from '../../../../common/components/Card/IconSelectionCard';

interface ProductSelectionProps {
  products: Product[];
  selectedProductId: string | null;
  onSelect: (id: string) => void;
  onContinue: () => void;
}

export const ProductSelection: React.FC<ProductSelectionProps> = ({
  products,
  selectedProductId,
  onSelect,
  onContinue,
}) => {
  const { t } = useTranslation();

  return (
    <StepLayout title={t('product.title')} isNextDisabled={selectedProductId === null} onContinue={onContinue}>
      <GridList
        items={products}
        selectedIds={selectedProductId !== null ? [selectedProductId] : []}
        onChangeSelected={indexes => onSelect(indexes[0])}
        Card={IconSelectionCard}
      />
    </StepLayout>
  );
};
