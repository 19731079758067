import React from 'react';
import { Box, Text, VStack, BoxProps } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { Background } from '../Background';

interface LoadingPageProps extends BoxProps {
  text?: string;
  size?: string;
  color?: string;
  borderColor?: string;
  animationDuration?: string;
  isFullPage?: boolean;
  containerHeight?: string;
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const LoadingPage: React.FC<LoadingPageProps> = ({
  text,
  size = '130px',
  color = '#8A31FF',
  borderColor = '#3D3D45',
  animationDuration = '2s',
  isFullPage = false,
  containerHeight,
  ...props
}) => {
  const height = containerHeight || (isFullPage ? '100vh' : '100%');

  return (
    <Background
      minHeight={height}
      height={height}
      position={isFullPage ? 'fixed' : 'relative'}
      zIndex={isFullPage ? 999 : 'auto'}>
      <Box
        bg="#1A1A1E"
        border={`1px solid ${borderColor}`}
        p={{ base: 4, md: 6 }}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...props}>
        <VStack spacing={4}>
          <Box
            width={{ base: '80px', sm: '100px', md: size }}
            height={{ base: '80px', sm: '100px', md: size }}
            borderRadius="50%"
            background={`conic-gradient(from 180deg at 50% 50%, ${color} 0deg, rgba(29, 0, 58, 0) 360deg)`}
            animation={`${spin} ${animationDuration} linear infinite`}
          />
          {text && (
            <Text color="white" fontSize={{ base: 'md', md: 'lg' }} textAlign="center">
              {text}
            </Text>
          )}
        </VStack>
      </Box>
    </Background>
  );
};
