import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { UniversalButton } from '../UniversalButton';
import { Icon } from '../Icons/Icons';

interface StepLayoutProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  isNextDisabled: boolean;
  onContinue: () => void;
}

export const StepLayout: React.FC<StepLayoutProps> = ({ title, description, children, isNextDisabled, onContinue }) => {
  return (
    <Box
      bg="#1A1A1E"
      border="1px solid #3D3D45"
      p={{ base: 3, md: 6 }}
      borderRadius="8px"
      height={{ base: '60%', lg: '65%' }}
      flexDirection="column"
      display="flex"
      justifyContent="space-between">
      <Box maxHeight={'80%'} height={'80%'}>
        <Heading size={{ base: 'md', sm: 'lg', md: 'xl' }} color="white" maxHeight={'20%'} mb={{ base: 2, md: 4 }}>
          {title}
        </Heading>
        {description && (
          <Text color="gray.400" mb={4} maxHeight={'10%'}>
            {description}
          </Text>
        )}
        {children}
      </Box>
      <UniversalButton
        buttonTextKey="button.continue"
        isDisabled={isNextDisabled}
        onClick={onContinue}
        colorScheme="purple"
        rightIcon={<Icon iconCode={'Lucide:Zap'} />}
        mt={{ base: 2, md: 4 }}
        alignSelf="flex-end"
        maxHeight={'20%'}
      />
    </Box>
  );
};
