import { useState, useEffect } from 'react';
import { PlanResponse } from '../../../../common/models';
import { useSubscriptionPlans } from '../../../../api/useSubscriptionPlans';
import { useFormatCurrency } from '../../../../common/hooks/useFormatCurrency';

export interface FormattedPlanProps {
  id: string;
  name: string;
  price: string;
  features: string[];
  isRecommended: boolean;
}

const formatPlanResponse = (plan: PlanResponse): FormattedPlanProps => {
  const formatCurrency = useFormatCurrency();
  const price = formatCurrency(Number(plan.unitAmount), plan.currency);

  return {
    id: plan.id,
    name: plan.name,
    price: `${price}/${plan.interval}`,
    features: [
      `${plan.isVideoGenerationsUnlimited ? 'Unlimited' : plan.videoGenerationsPerInterval} video generations`,
      plan.description,
    ],
    isRecommended: plan.isRecommended,
  };
};

export const usePricingPlansList = () => {
  const [formattedPlans, setFormattedPlans] = useState<FormattedPlanProps[]>([]);
  const { data: plans, isLoading, error } = useSubscriptionPlans();

  useEffect(() => {
    if (plans) {
      const formatted = plans.map(formatPlanResponse);
      setFormattedPlans(formatted);
    }
  }, [plans]);

  return { pricingPlansList: formattedPlans, isLoading, error };
};
