import React from 'react';
import { Image, Text, VStack, AspectRatio, Box } from '@chakra-ui/react';
import { useDateFormatter } from '../../hooks/useDateFormatter';
import { Card } from './Card';

interface VideoCardProps {
  title: string;
  thumbnailUrl: string;
  createdAt: Date | string;
}

export const VideoCard: React.FC<VideoCardProps> = ({ title, thumbnailUrl, createdAt }) => {
  const formatDate = useDateFormatter();

  return (
    <Card variant="video">
      <AspectRatio ratio={3 / 4} width="100%">
        <Box bg="gray.700" borderRadius="md" overflow="hidden">
          {thumbnailUrl ? (
            <Image src={thumbnailUrl} alt={title} objectFit="cover" width="100%" height="100%" />
          ) : (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              <Text color="gray.500">No image</Text>
            </Box>
          )}
        </Box>
      </AspectRatio>
      <VStack align="start" p={3} spacing={1}>
        <Text color="white" fontWeight="bold" noOfLines={1}>
          {title}
        </Text>
        <Text color="gray.400" fontSize="sm">
          {formatDate(createdAt)}
        </Text>
      </VStack>
    </Card>
  );
};
