import React, { ReactNode } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  children?: ReactNode;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmText,
  cancelText,
  confirmButtonColor = 'red',
  cancelButtonColor = 'purple',
  children,
}) => {
  const { t } = useTranslation();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent bg="#1A1A1E" borderColor="#3D3D45">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
            {title || t('projectDetail.deleteConfirmation')}
          </AlertDialogHeader>

          <AlertDialogBody color="gray.300">
            {description || t('projectDetail.deleteWarning')}
            {children}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} colorScheme={cancelButtonColor}>
              {cancelText || t('button.cancel')}
            </Button>
            <Button colorScheme={confirmButtonColor} onClick={onConfirm} ml={3}>
              {confirmText || t('button.delete')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
