import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { createModal } from '../../../common/components/UniversalModal/сreateModal';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
interface VideoModalProps {
  videoPath?: string;
}
export const VideoModal: React.FC<VideoModalProps> = ({ videoPath }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width={isMobile ? '100vw' : '100%'}
      height={isMobile ? '100vh' : '100%'}
      position={isMobile ? 'fixed' : 'relative'}
      top={isMobile ? '0' : 'auto'}
      left={isMobile ? '0' : 'auto'}
      bg={isMobile ? 'black' : 'transparent'}>
      <Box
        width={isMobile ? '100%' : '90%'}
        height={isMobile ? '100%' : 'auto'}
        maxHeight={isMobile ? '100vh' : '80vh'}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <video
          style={{
            width: '100%',
            height: isMobile ? '100%' : 'auto',
            maxHeight: isMobile ? '100%' : '80vh',
            objectFit: 'contain',
            display: 'block',
          }}
          controls
          autoPlay
          playsInline>
          <source src={videoPath || ''} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Flex>
  );
};

export const { VideoProvider, useVideoModal } = createModal(VideoModal, 'Video', {
  showCloseButton: true,
  contentProps: {
    maxW: { base: '100vw', md: '90vw', lg: '95vw', xl: '98vw', '2xl': '99vw' },
    maxH: { base: '100vh', md: '90vh', lg: '95vh', xl: '98vh', '2xl': '99vh' },
    minH: { base: '100vh', md: 'auto' },
    borderRadius: { base: 0, md: 'md' },
    overflow: 'hidden',
    my: { base: '0', lg: 3 },
    bg: 'black',
  },
});
