import { useEffect, useState } from 'react';
import { useProject } from './useProject';
import { useDownloadVideo } from '../../../features/DownloadVideo/useDownloadVideo';
import { useProjectDeletion } from './useProjectDeletion';

export const useProjectDetail = (projectId: string) => {
  const { project, isLoading, error, updateProjectName } = useProject(projectId);
  const { downloadVideo, isDownloading } = useDownloadVideo();
  const { handleDelete, isDeleting } = useProjectDeletion(projectId);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (project?.project.name) {
      setTitle(project.project.name);
    }
  }, [project?.project.name]);

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);
  };

  const handleTitleBlur = async () => {
    if (project?.project.name !== title) {
      await updateProjectName(title);
    }
  };

  const handleDownload = () => {
    const media = project?.medias?.[0];
    if (media?.videoFilePath) {
      downloadVideo(media.videoFilePath, `${project?.project?.name}.mp4`);
    } else {
      console.error('Video file path is not available');
    }
  };

  return {
    project,
    title,
    isLoading,
    error,
    isDownloading,
    isDeleting,
    handleTitleChange,
    handleTitleBlur,
    handleDownload,
    handleDelete,
  };
};
