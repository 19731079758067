import { useState } from 'react';
import { useGenerateMedia } from '../../../api/useMedia';
import { Media } from '../../../common/models';

export interface SelectedData {
  niche: string | null;
  avatar: string | null;
  product: string | null;
  hooks: string | null;
  ctaLink: string | null;
}

export const useVideoCreation = () => {
  const [selectedData, setSelectedData] = useState<SelectedData>({
    niche: null,
    avatar: null,
    hooks: null,
    product: null,
    ctaLink: null,
  });
  const generateMedia = useGenerateMedia();

  const initializeFromMedia = async (media: Media) => {
    setSelectedData({
      niche: media.niche?.id || null,
      product: media.product?.id || null,
      hooks: media.hookStyle?.id || null,
      avatar: media.avatar?.id || null,
      ctaLink: media.cta || null,
    });
  };

  const handleNicheSelection = (nicheIds: string) => {
    setSelectedData(prev => ({
      ...prev,
      niche: nicheIds,
      product: null,
      hooks: null,
      avatar: null,
    }));
  };

  const handleProductSelection = (id: string) => {
    setSelectedData(prev => ({
      ...prev,
      product: id,
      hooks: null,
      avatar: null,
    }));
  };

  const handleHookSelection = (hookId: string) => {
    setSelectedData(prev => ({
      ...prev,
      hooks: hookId,
      avatar: null,
    }));
  };

  const handleAvatarSelection = (avatarIds: string) => {
    setSelectedData(prev => ({
      ...prev,
      avatar: avatarIds,
    }));
  };

  const handleCTAChange = (ctaLink: string | null) => {
    setSelectedData(prev => ({ ...prev, ctaLink }));
  };

  const handleVideoGeneration = async (mediaId: string) => {
    try {
      await generateMedia.mutateAsync(mediaId);
      console.log('Video generated successfully');
    } catch (error) {
      console.error('Failed to generate media:', error);
    }
  };

  const resetVideoCreation = () => {
    setSelectedData({
      niche: null,
      avatar: null,
      hooks: null,
      ctaLink: null,
      product: null,
    });
  };

  return {
    selectedData,
    handleNicheSelection,
    handleAvatarSelection,
    handleHookSelection,
    handleProductSelection,
    handleCTAChange,
    handleVideoGeneration,
    resetVideoCreation,
    initializeFromMedia,
  };
};
