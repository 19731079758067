export const environment = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  },
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: `${window.location.origin}`,
  },
};
