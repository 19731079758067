import React from 'react';
import { Box, Progress, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CreditsProgressProps {
  currentCredits: number;
  maxCredits: number;
  interval: string;
  onAddCredits: () => void;
}

export const CreditsProgress: React.FC<CreditsProgressProps> = ({
  currentCredits,
  maxCredits,
  interval,
  onAddCredits,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Progress
        value={((maxCredits - currentCredits) / maxCredits) * 100}
        size="sm"
        mb={1}
        background="#27272A"
        sx={{
          '& > div:first-of-type': {
            background: 'linear-gradient(90deg, #40107D 0%, #731EE3 100%)',
          },
        }}
        borderRadius="4px"
      />
      <Text fontSize="xs" color="#a0a0a0" mb={3}>
        {t('sidebar.creditsLeft', {
          attemptsLeft: maxCredits - currentCredits,
          maxAttempts: maxCredits,
          interval: interval,
        })}
      </Text>
      <Button
        variant="outline"
        background="linear-gradient(180deg, #731EE3 0%, #40107D 100%)"
        color="white"
        border="none"
        size="sm"
        w="100%"
        onClick={onAddCredits}
        _hover={{
          background: 'linear-gradient(180deg, #8A31FF 0%, #5A1EAE 100%)',
        }}>
        {t('sidebar.addCredits')}
      </Button>
    </Box>
  );
};
