import React from 'react';
import { VStack, HStack, Text, Flex, Avatar, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { usePricingPlansList } from '../hooks/pricing/usePricingPlansList';
import { LoadingPage } from '../../../common/components/LoadingPage';
import { useActivePlan } from '../hooks/pricing/useActivePlan';
import { PlanCardWrapper } from './PlanCardWrapper';
import { createModal } from '../../../common/components/UniversalModal/сreateModal';
import { useMobileSidebar } from '../../../common/components/Layout/components/MobileSidebarProvider';

export const PricingModalContent: React.FC = () => {
  const { t } = useTranslation();
  const { pricingPlansList, isLoading, error } = usePricingPlansList();
  const { activePlan } = useActivePlan();
  const { isOpen, onToggle } = useMobileSidebar();
  if (isOpen) {
    onToggle();
  }

  return (
    <Box
      width="100%"
      height={{ base: '100%', lg: 'auto' }}
      maxWidth="100%"
      mx="auto"
      p={{ base: '3%', sm: '4%', md: '5%', lg: '3%' }}
      overflow={{ base: 'auto', lg: 'visible' }}
      display="flex"
      flexDirection="column"
      justifyContent={{ base: 'flex-start', lg: 'center' }}>
      <Text
        fontSize={{ base: '130%', sm: '150%', md: '180%' }}
        textAlign="center"
        mb={{ base: '4%', sm: '5%', md: '4%', lg: '2%' }}
        color="white">
        {t('pricing.title')}
      </Text>

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" maxH="35%">
          <LoadingPage width="100%" height="100%" text={t('loading.pricing')} />
        </Flex>
      ) : error ? (
        <Text color="red.500" textAlign="center">
          {error.message}
        </Text>
      ) : (
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          gap={{ base: '4%', sm: '5%', md: '6%', lg: '2%' }}
          justifyContent="center"
          alignItems={{ base: 'center', lg: 'stretch' }}
          mb={{ base: '6%', sm: '7%', md: '8%', lg: '3%' }}>
          {pricingPlansList.map((plan, index) => (
            <Box key={index} width={{ base: '90%', sm: '85%', md: 'auto' }} maxWidth="100%">
              <PlanCardWrapper plan={plan} isActive={activePlan?.name === plan.name} />
            </Box>
          ))}
        </Flex>
      )}

      <VStack
        spacing={{ base: '3%', sm: '4%', md: '5%', lg: '2%' }}
        align="center"
        maxWidth={{ base: '95%', sm: '90%', md: '70%' }}
        mx="auto"
        mt={{ base: '4%', sm: '5%', md: '6%', lg: '2%' }}>
        <Text fontSize={{ base: '120%', sm: '140%', md: '160%' }} fontWeight="bold" color="yellow.400">
          ★ ★ ★ ★ ★
        </Text>
        <Text
          textAlign="center"
          color="gray.300"
          fontSize={{ base: '90%', sm: '100%', md: '120%' }}
          px={{ base: '2%', sm: '3%' }}>
          {t('pricing.comment')}
        </Text>
        <HStack spacing={{ base: '2%', md: '3%' }} mt={{ base: '3%', md: '4%' }}>
          <Avatar size={{ base: 'sm', md: 'md' }} name="Siddharth Gopi" bg="orange.500">
            SG
          </Avatar>
          <VStack align="start" spacing="1%">
            <Text fontWeight="bold" color="white" fontSize={{ base: '90%', sm: '100%', md: '120%' }}>
              Siddharth Gopi
            </Text>
            <Text fontSize={{ base: '80%', sm: '90%', md: '100%' }} color="gray.400">
              Co-founder at Tophire.co
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export const { PricingProvider, usePricingModal } = createModal(PricingModalContent, 'Pricing', {
  size: '6xl',
  showCloseButton: true,
  contentProps: {
    maxW: { base: '100vw', md: '90vw', lg: '1200px' },
    minH: { base: '100vh', md: 'auto' },
    borderRadius: { base: 0, md: 'md' },
    overflow: 'auto',
    my: { base: '0', lg: 3 },
  },
});
