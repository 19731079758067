import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Icon } from '../Icons/Icons';
import { useTranslation } from 'react-i18next';

interface UniversalButtonProps extends ButtonProps {
  iconCode?: string;
  buttonTextKey: string;
}

export const UniversalButton: React.FC<UniversalButtonProps> = ({ iconCode, buttonTextKey, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button {...props} rightIcon={iconCode ? <Icon iconCode={iconCode} /> : undefined}>
      {t(buttonTextKey)}
    </Button>
  );
};
