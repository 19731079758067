import React from 'react';
import { Box, Badge, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';

export const FreePlanBadge: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Badge
        border="1px solid"
        bg="linear-gradient(180deg, rgba(207, 170, 255, 0.15) 6.25%, rgba(152, 105, 213, 0.15) 100%)"
        color="#9747FF"
        px={2}
        py={1}
        borderRadius="full"
        fontSize="xs"
        display="inline-flex"
        alignItems="center"
        mb={2}>
        <Icon iconCode={'Lucide:Zap'} mr={1} boxSize={3} />
        {t('sidebar.freeLabel')}
      </Badge>
      <Text fontSize="14px" color="white" mb={3}>
        {t('sidebar.startForFree')}
      </Text>
    </Box>
  );
};
