import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Input, Button, Flex, Stack, Image, VStack } from '@chakra-ui/react';
import { Icon } from '../../../../common/components/Icons';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '../../../../common/components/LoadingPage';
import { useProjectList } from '../../../../api/useProjects';
import { useDownloadVideo } from '../../../../features/DownloadVideo/useDownloadVideo';
import { useVideoModal } from '../../../../features/Modals/VideoModal/VideoModal';

interface FinalizeVideoProps {
  mediaId: string;
  onGenerateMore: () => void;
  onGenerate: () => void;
  updateProjectName: (newName: string) => Promise<void>;
}

export const FinalizeVideo: React.FC<FinalizeVideoProps> = ({
  mediaId,
  onGenerateMore,
  onGenerate,
  updateProjectName,
}) => {
  const [title, setTitle] = useState('');
  const [previewFilePath, setPreviewFilePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { data: projects, refetch } = useProjectList();
  const [VideoFilePath, setVideoFilePath] = useState('');
  const [Description, setDescription] = useState('');
  const { t } = useTranslation();
  const { downloadVideo, isDownloading } = useDownloadVideo();
  const { open } = useVideoModal();

  useEffect(() => {
    let isCancelled = false;
    const checkStatus = async () => {
      if (isCancelled) return;
      await refetch();
      const currentProject = projects?.find(project => project.medias.some(media => media.id === mediaId));

      const currentMedia = currentProject?.medias.find(media => media.id === mediaId);

      if (currentMedia?.status === 'Completed') {
        setTitle(currentProject?.project.name || 'Untitled');
        setPreviewFilePath(currentMedia.previewFilePath || '');
        setVideoFilePath(currentMedia.videoFilePath || '');
        setDescription(currentMedia.description || '');
        setIsLoading(false);
      } else {
        setTimeout(checkStatus, 5000);
      }
    };

    if (isLoading) {
      checkStatus();
    }
    return () => {
      isCancelled = true;
    };
  }, [isLoading, projects, mediaId]);

  useEffect(() => {
    const generateVideo = async () => {
      setIsLoading(true);
      await onGenerate();
    };

    generateVideo();
  }, []);

  const handleOpen = () => {
    open({ videoPath: VideoFilePath || '' });
  };

  const handleDownload = async () => {
    const currentProject = projects?.find(project => project.medias.some(media => media.id === mediaId));
    const currentMedia = currentProject?.medias.find(media => media.id === mediaId);

    if (!currentMedia?.videoFilePath) {
      console.log('Error media empty');
      return;
    }

    try {
      const fileName = `${currentProject?.project.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.mp4`;
      await downloadVideo(currentMedia.videoFilePath, fileName);
      console.log('Video download success');
    } catch (error) {
      console.log(`Error download ${error}`);
    }
  };

  if (isLoading) {
    return <LoadingPage text={t('finalize.generating')} containerHeight="80%" />;
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleTitleBlur = async () => {
    await updateProjectName(title);
  };

  return (
    <Box
      bg="#1A1A1E"
      border="1px solid #3D3D45"
      p={{ base: '4%', sm: '3%', md: '2.5%', lg: '2%' }}
      height={{ base: 'auto', md: '70%' }}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '1rem', sm: '1.5rem', md: '2rem', xl: '2.5rem' }}
        height={'100%'}>
        <Box position="relative" width={{ base: '100%', md: '50%' }} pb={{ base: '80%', md: '28.5%' }}>
          <Box position="absolute" top="0" left="0" right="0" bottom="0" overflow="hidden" borderRadius="0.5rem">
            <Image
              src={previewFilePath}
              alt="Video preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              onClick={handleOpen}
            />
            <Flex
              className="play-icon"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              bg="rgba(0, 0, 0, 0.7)"
              w="50px"
              h="50px"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              opacity={0.8}
              transition="all 0.2s"
              onClick={handleOpen}>
              <Icon iconCode="Lucide:Play" color="white" width="24px" height="24px" />
            </Flex>
          </Box>
        </Box>
        <Box width={{ base: '100%', md: '50%' }} mt={{ base: '1.5rem', md: 0 }} height={{ base: 'auto', md: '100%' }}>
          <Heading size={{ base: 'md', lg: 'lg' }} color="white" height="8%" mb="2%" textOverflow="ellipsis">
            {t('finalize.title')}
          </Heading>
          <Box height="85%" bg="#1A1A1E" borderRadius="md">
            <VStack align="stretch" spacing={{ base: '1.5rem', md: '2rem' }} height="100%">
              <Box>
                <Text color="white" mb={2} fontSize={{ base: 'sm', md: 'md' }}>
                  {t('finalize.videoTitle')}
                </Text>
                <Input
                  value={title}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  bg="#27272A"
                  border="1px solid #3D3D45"
                  color="white"
                  fontSize={{ base: 'sm', md: 'md' }}
                />
              </Box>
              <Box flex="1" overflow="auto">
                <Text color="white" mb={2} fontSize={{ base: 'sm', md: 'md' }}>
                  {t('finalize.description')}
                </Text>
                <Text color="gray.400" fontSize={{ base: 'xs', md: 'sm' }}>
                  {Description}
                </Text>
              </Box>
              <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
                <Button
                  leftIcon={<Icon iconCode={'Lucide:Download'} />}
                  colorScheme="purple"
                  flex="1"
                  onClick={handleDownload}
                  isLoading={isDownloading}
                  fontSize={{ base: '0.9rem', xl: '1rem' }}
                  minH={{ base: '2.5rem', md: '3rem' }}>
                  {isDownloading ? t('button.downloading') : t('button.download')}
                </Button>
                <Button
                  leftIcon={<Icon iconCode={'Lucide:RefreshCw'} />}
                  variant="outline"
                  colorScheme="purple"
                  flex="1"
                  onClick={onGenerateMore}
                  fontSize={{ base: '0.9rem', xl: '1rem' }}
                  minH={{ base: '2.5rem', md: '3rem' }}>
                  {t('button.generateMore')}
                </Button>
              </Stack>
            </VStack>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
