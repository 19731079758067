import React from 'react';
import { HookSelection } from '../../components/HookSelection/HookSelection';
import { useHookStyleList } from '../../../../api/useHookStyleList';
import { useTranslation } from 'react-i18next';

interface HookSelectionContainerProps {
  avatarId?: string | null;
  nicheId?: string | null;
  hookStyleId?: string | null;
  onContinue: () => void;
  onHookStyleSelect: (id: string) => void;
}

export const HookSelectionContainer: React.FC<HookSelectionContainerProps> = ({
  onContinue,
  nicheId,
  onHookStyleSelect,
  hookStyleId,
}) => {
  const { data: hookStyles, isLoading } = useHookStyleList(nicheId || '');
  const { t } = useTranslation();

  if (isLoading) {
    return <div>{t('loading.default')}</div>;
  }

  const handleHookStyleSelection = (id: string) => {
    onHookStyleSelect(id);
  };

  return (
    <HookSelection
      hookStyles={hookStyles || []}
      selectedHookId={hookStyleId || null}
      onSelect={handleHookStyleSelection}
      onContinue={onContinue}
    />
  );
};
