import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export interface CardProps extends BoxProps {
  variant?: 'default' | 'pricing' | 'pricing-second' | 'video';
}

export const Card: React.FC<CardProps> = ({ children, variant = 'default', ...props }) => {
  const getVariantStyles = () => {
    switch (variant) {
      case 'pricing':
        return {
          border: '1px solid #3D3D45',
          bg: '#1E1E1E',
        };
      case 'pricing-second':
        return {
          border: '2px solid #5F19BA',
          bg: '#2C1E42',
        };
      case 'video':
        return {
          bg: '#1E1E1E',
        };
      default:
        return {};
    }
  };

  return (
    <Box borderRadius="md" overflow="hidden" {...getVariantStyles()} {...props}>
      {children}
    </Box>
  );
};
