import React, { useCallback, useEffect, useState } from 'react';
import { Box, Input, Flex, Text } from '@chakra-ui/react';
import { Steps } from '../../common/components/Steps';
import { steps } from '../../config/stepsConfig';
import { NicheSelectionContainer } from './containers/NicheSelectionContainer/NicheSelectionContainer';
import { HookSelectionContainer } from './containers/HookSelectionContainer/HookSelectionContainer';
import { FinalizeVideoContainer } from './containers/FinalizeVideoContainer/FinalizeVideoContainer';
import { SelectedData, useVideoCreation } from './hooks/useVideoCreation';
import { useSteps } from './hooks/useSteps';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useProject } from './hooks/useProject';
import { useUpdateMediaData } from './hooks/useUpdateMediaData';
import { AppMainLayout } from '../../AppMainLayout';
import { useIsMobile } from '../../common/hooks/useIsMobile';
import { ProductSelectionContainer } from './containers/ProductSelectionContainer/ProductSelectionContainer';
import { LoadingPage } from '../../common/components/LoadingPage';
import { useCreateProject } from '../../features/ProjectCreation/hooks/useCreateProject';
import { useStepsCompletion } from './hooks/useStepsCompletion';
import { Step } from '../../common/components/Steps/Steps';
import { useInitialStep } from './hooks/useInitialStep';

export const VideoCreatePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const isMobile = useIsMobile();
  const [isInitializing, setIsInitializing] = useState(true);
  const [isInitializingData, setIsInitializingData] = useState(true);
  const navigate = useNavigate();
  const { project: project, updateProjectName, isUpdating } = useProject(projectId || '');
  const mediaId = project?.medias[0]?.id || '';
  const media = project?.medias.find(m => m.id === mediaId);
  const [localProjectName, setLocalProjectName] = useState('Untitled');
  const {
    selectedData,
    handleNicheSelection,
    handleHookSelection,
    handleVideoGeneration,
    handleProductSelection,
    resetVideoCreation,
    initializeFromMedia,
  } = useVideoCreation();
  const updateMediaData = useUpdateMediaData(selectedData, mediaId);
  const { determineInitialStep } = useInitialStep();
  const { currentStep, changeStep, restartStep, nextStep } = useSteps(steps.length);
  const { getCompletedSteps } = useStepsCompletion<SelectedData, Step>(steps);
  const { t } = useTranslation();
  const { createProject } = useCreateProject();

  useEffect(() => {
    if (project) {
      setLocalProjectName(project.project.name);
      if (media && isInitializing) {
        if (media?.status === 'Completed') {
          navigate(`/projects/${projectId}`);
          return;
        }
        initializeFromMedia(media);
        setIsInitializing(false);
      }
    }
  }, [project]);

  useEffect(() => {
    if (isInitializingData && Object.values(selectedData).some(value => value !== null)) {
      changeStep(determineInitialStep(media), selectedData);
      setIsInitializingData(false);
    }
  }, [selectedData]);

  useEffect(() => {
    if (!isInitializing) {
      const updateMedia = async () => {
        if (mediaId && currentStep > 1) {
          try {
            await updateMediaData();
            console.log('Media data updated after selection change');
          } catch (error) {
            console.error('Failed to update media data:', error);
          }
        }
      };
      updateMedia();
    }
  }, [currentStep]);

  const handleGenerateMore = async () => {
    await createProject('Untitled');
    resetVideoCreation();
    restartStep();
  };

  const handleGenerateVideo = useCallback(async () => {
    if (mediaId) {
      try {
        await updateMediaData();
        console.log('Media data updated before video generation');
        handleVideoGeneration(mediaId);
      } catch (error) {
        console.error('Failed to update media data before video generation:', error);
      }
    }
  }, [mediaId, updateMediaData, handleVideoGeneration]);

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalProjectName(e.target.value);
  };

  const handleProjectNameBlur = () => {
    if (project && localProjectName !== project.project.name) {
      updateProjectName(localProjectName);
    }
  };

  if (isInitializing) {
    return <LoadingPage text={t('loading.default')} />;
  }

  return (
    <AppMainLayout>
      <Box height="100%" width="100%">
        <Flex justifyContent="center" alignItems="center" mb={4} px={4} py={2} bg="#1A1A1E" maxHeight="15%">
          <Input
            textAlign="end"
            value={localProjectName}
            onChange={handleProjectNameChange}
            onBlur={handleProjectNameBlur}
            variant="unstyled"
            fontWeight="bold"
            color="white"
            _hover={{ bg: '#2D2D34' }}
            _focus={{ bg: '#2D2D34', boxShadow: 'none' }}
            px={2}
            py={1}
            borderRadius="md"
            maxWidth="300px"
            mr={3}
            isDisabled={isUpdating}
          />
          <Text color="gray.500" fontSize="sm">
            {isUpdating ? t('createVideo.saving') : t('createVideo.autoSaved')}
          </Text>
        </Flex>
        <Steps
          steps={getCompletedSteps(selectedData)}
          currentStep={currentStep}
          isMobile={isMobile}
          onChange={step => changeStep(step, selectedData)}
        />
        {currentStep === 1 && (
          <NicheSelectionContainer
            nicheIdSelected={selectedData.niche}
            onContinue={nextStep}
            onNicheSelect={handleNicheSelection}
          />
        )}
        {currentStep === 2 && (
          <ProductSelectionContainer
            productIdSelected={selectedData.product}
            nicheId={selectedData.niche}
            onContinue={nextStep}
            onProductSelect={handleProductSelection}
          />
        )}
        {currentStep === 3 && (
          <HookSelectionContainer
            hookStyleId={selectedData.hooks}
            nicheId={selectedData.niche}
            onContinue={nextStep}
            onHookStyleSelect={handleHookSelection}
            avatarId={selectedData.avatar}
          />
        )}
        {currentStep === 4 && (
          <FinalizeVideoContainer
            mediaId={mediaId}
            onGenerateMore={() => {
              handleGenerateMore();
            }}
            onGenerate={handleGenerateVideo}
            updateProjectName={updateProjectName}
          />
        )}
      </Box>
    </AppMainLayout>
  );
};
