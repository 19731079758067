import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';

interface ProjectControlPanelProps {
  isSelectionMode: boolean;
  toggleSelectionMode: () => void;
  handleSelectAll: () => void;
  handleDeleteSelected: () => void;
  selectedProjectsCount: number;
  isDeleting: boolean;
  isAllSelected: boolean;
  isMobile?: boolean;
}

export const ProjectControlPanel: React.FC<ProjectControlPanelProps> = ({
  isSelectionMode,
  toggleSelectionMode,
  handleSelectAll,
  handleDeleteSelected,
  selectedProjectsCount,
  isDeleting,
  isAllSelected,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  return (
    <HStack spacing={4} flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'}>
      <Button
        colorScheme="purple"
        onClick={toggleSelectionMode}
        isDisabled={isDeleting}
        aria-label={isSelectionMode ? t('dashboard.cancelSelection') : t('dashboard.selectProjects')}
        minW="fit-content">
        <Icon iconCode={isSelectionMode ? 'Lucide:X' : 'Lucide:Check'} />
        <Text ml={2}>{isSelectionMode ? t('dashboard.cancelSelection') : t('dashboard.selectProjects')}</Text>
      </Button>
      {isSelectionMode && (
        <>
          <Button
            colorScheme="purple"
            onClick={handleSelectAll}
            aria-label={t('dashboard.selectAll')}
            isDisabled={isDeleting}
            minW="fit-content">
            <Icon iconCode={isAllSelected ? 'Lucide:Square' : 'Lucide:CheckSquare'} />
            <Text ml={2}>{t('dashboard.selectAll')}</Text>
          </Button>
          <Button
            colorScheme="red"
            onClick={handleDeleteSelected}
            isDisabled={selectedProjectsCount === 0 || isDeleting}
            isLoading={isDeleting}
            aria-label={t('button.delete')}
            minW="fit-content">
            <Icon iconCode="Lucide:Trash" />
            <Text ml={2}>{t('button.delete')}</Text>
          </Button>
        </>
      )}
    </HStack>
  );
};
