import { useState } from 'react';
import { ProjectWithMedia } from '../../../common/models';
import { useNavigate } from 'react-router-dom';

export const useProjectSelection = (projects: ProjectWithMedia[]) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const navigate = useNavigate();

  const handleSelectProject = (projectId: string) => {
    setSelectedProjects(prev =>
      prev.includes(projectId) ? prev.filter(id => id !== projectId) : [...prev, projectId],
    );
  };

  const handleSelectAll = () => {
    setSelectedProjects(selectedProjects.length === projects.length ? [] : projects.map(project => project.project.id));
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    if (isSelectionMode) {
      setSelectedProjects([]);
    }
  };

  const handleCardClick = (project: ProjectWithMedia) => {
    if (isSelectionMode) {
      handleSelectProject(project.project.id);
      return;
    }
    const media = project.medias[0];
    if (!media) return;

    switch (media.status) {
      case 'Draft':
      case 'Processing': {
        navigate(`/create-video?projectId=${project.project.id}`);
        break;
      }
      default:
        navigate(`/projects/${project.project.id}`);
    }
  };

  return {
    selectedProjects,
    isSelectionMode,
    handleSelectProject,
    handleSelectAll,
    handleCardClick,
    toggleSelectionMode,
  };
};
