import { steps as configSteps } from '../../../config/stepsConfig';

type StepWithCompletion<T> = T & { isCompleted: boolean };

export const useStepsCompletion = <TData, TStep extends { nameModel?: string }>(
  steps: TStep[] = configSteps as unknown as TStep[],
) => {
  const getCompletedSteps = (data: TData): StepWithCompletion<TStep>[] => {
    return steps.map(step => ({
      ...step,
      isCompleted: checkStepCompletion(step, data),
    }));
  };

  const checkStepCompletion = (step: TStep, data: TData): boolean => {
    if (!step.nameModel) return false;
    return !!data[step.nameModel as keyof TData];
  };

  return {
    getCompletedSteps,
  };
};
