import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ProjectDetailItemProps {
  label: string;
  value: string | null | undefined;
}

export const ProjectDetailItem: React.FC<ProjectDetailItemProps> = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <Box mb={'2%'}>
      <Text color="white" mb={2} fontSize={{ base: 'sm', sm: 'md', lg: 'lg' }}>
        {t(label)}
      </Text>
      <Text color="gray.400" fontSize={{ base: 'sm', sm: 'md' }}>
        {value}
      </Text>
    </Box>
  );
};
