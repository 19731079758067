import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import { useChangePassword } from '../../auth/useChangePassword';
import { useUserProfile } from '../../api/useUserProfile';
import { useTranslation } from 'react-i18next';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { changePassword, isLoading, error } = useChangePassword();
  const { data: userProfile } = useUserProfile();

  const handleChangePassword = async () => {
    if (!userProfile?.user.email) {
      console.error('User email not available');
      return;
    }

    try {
      await changePassword({ email: userProfile.user.email });
      onClose();
    } catch (err) {
      console.error('Failed to initiate password change:', err);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#1a1a1a" color={'white'}>
        <ModalHeader>{t('changePassword.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t('changePassword.confirmMessage')}</Text>
          {error && <Text color="red.500">{error}</Text>}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="black" border="1px solid #3D3D45" mr={3} onClick={onClose}>
            {t('button.cancel')}
          </Button>
          <Button colorScheme="purple" onClick={handleChangePassword} isLoading={isLoading}>
            {t('changePassword.confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
