import * as LucideIcons from 'lucide-react';
import * as RadixIcons from '@radix-ui/react-icons';
import { Icon as ChakraIcon, IconProps as ChakraIconProps } from '@chakra-ui/react';
import React from 'react';

const iconLibraries = {
  Lucide: LucideIcons,
  Radix: RadixIcons,
};

type IconName = keyof typeof LucideIcons | keyof typeof RadixIcons;
type IconLibrary = keyof typeof iconLibraries;

export type IconCode = `${IconLibrary}:${IconName}` | string;

interface IconProps extends Omit<ChakraIconProps, 'as'> {
  iconCode: IconCode;
}

export const Icon = ({ iconCode, ...props }: IconProps) => {
  const [library, iconName] = iconCode.split(':') as [IconLibrary, IconName];
  const iconLibrary = iconLibraries[library] || {};
  const IconComponent = iconLibrary[iconName as keyof typeof iconLibrary] || undefined;

  return <ChakraIcon as={IconComponent} {...props} />;
};

export { iconLibraries };
