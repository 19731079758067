import React from 'react';
import { Box, Heading, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';
import { ProjectControlPanel } from './ProjectControlPanel';

interface VideoGridHeaderProps {
  isSelectionMode: boolean;
  toggleSelectionMode: () => void;
  handleSelectAll: () => void;
  onOpen: () => void;
  selectedProjectsCount: number;
  isDeleting: boolean;
  totalProjectsCount: number;
  createProject: (name: string) => void;
}

export const DesktopVideoGridHeader: React.FC<VideoGridHeaderProps> = ({
  isSelectionMode,
  toggleSelectionMode,
  handleSelectAll,
  onOpen,
  selectedProjectsCount,
  isDeleting,
  totalProjectsCount,
  createProject,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="stretch" mb={6}>
      <Heading color="white" mb={6}>
        {t('dashboard.projects')}
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ProjectControlPanel
          isSelectionMode={isSelectionMode}
          toggleSelectionMode={toggleSelectionMode}
          handleSelectAll={handleSelectAll}
          handleDeleteSelected={onOpen}
          selectedProjectsCount={selectedProjectsCount}
          isDeleting={isDeleting}
          isAllSelected={selectedProjectsCount > 0 && selectedProjectsCount === totalProjectsCount}
        />
        <Button
          colorScheme="purple"
          onClick={() => createProject('New Project')}
          leftIcon={<Icon iconCode="Lucide:Download" />}>
          {t('sidebar.createVideo')}
        </Button>
      </Box>
    </Box>
  );
};
