import React from 'react';
import { ProductSelection } from '../../components/ProductSelection/ProductSelection';
import { useTranslation } from 'react-i18next';
import { useProductList } from '../../../../api/useProductList';

interface ProductSelectionContainerProps {
  productIdSelected?: string | null;
  nicheId: string | null;
  onContinue: () => void;
  onProductSelect: (id: string) => void;
}

export const ProductSelectionContainer: React.FC<ProductSelectionContainerProps> = ({
  productIdSelected,
  nicheId,
  onContinue,
  onProductSelect,
}) => {
  const { data: products, isLoading } = useProductList(nicheId || '');
  const { t } = useTranslation();

  if (isLoading) {
    return <div>{t('loading.default')}</div>;
  }

  const handleProductSelection = (id: string) => {
    onProductSelect(id);
  };

  return (
    <ProductSelection
      products={products || []}
      selectedProductId={productIdSelected || null}
      onSelect={handleProductSelection}
      onContinue={onContinue}
    />
  );
};
