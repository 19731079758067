import { useState } from 'react';

export const useDownloadVideo = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadVideo = async (videoUrl: string, fileName: string) => {
    setIsDownloading(true);
    let url: string;
    try {
      const response = await fetch(videoUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      url = window.URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching video:', error);
      url = videoUrl;
    }
    try {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading video:', error);
      throw error;
    } finally {
      setIsDownloading(false);
    }
  };

  return { downloadVideo, isDownloading };
};
