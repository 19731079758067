import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingPage } from '../common/components/LoadingPage';
import { useTranslation } from 'react-i18next';

interface AuthGuardProps {
  children: React.ReactNode;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <LoadingPage text={t('loading.auth')} isFullPage={true} />;
  }

  return <>{children}</>;
};

export const withAuthGuard = (Component: React.FC) => {
  const ComponentWrapped = () => (
    <AuthGuard>
      <Component />
    </AuthGuard>
  );
  ComponentWrapped.displayName = `withAuthGuard(${Component.displayName})`;
  return ComponentWrapped;
};
